$light: #ffffff;
$gray : #e7e9eb;

.flex{
  display: flex;
}
.navbar{
  background-color: $light;
  padding: 1rem 2rem;
  border-bottom: 1px solid $gray;
}

.sidebar{
  width:14rem;
  height: calc(100vh - 52px);
  border-right: 1px solid $gray;
  ul{
    list-style:none;
    padding:1rem 2rem;
    li{
      margin-bottom:1rem;
      a{
        text-decoration:none;
        &.active{
          background-color: $gray
        }
      }
    }
  }

}

@media (min-width: 1199px) {
  .navbar .navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media (min-width: 768px) {
  .navbar-nav > li {
    float: left;
  }
}
.navbar-fixed-top {
  top: 0;
  border-width: 0 0 1px;
}
.navbar-header{
  float: left;
  margin: 5px 0px;
  max-width: 300px;
}
/*titulo de titan */
.navtexto {
  float: left;
    /* padding: 50px 15px; */
    position: relative;
    /* height: 50px; */
    line-height: 30px;
    padding: 10px 15px;
    font-size: 25px;
    margin: 0px 10px !important;
    text-decoration: none;
    background-color: transparent;
    color: white;
    margin: 0px 10px !important;
}
.navtexto:hover{
  color: white;
  background-color: transparent;
}
/*------------------------------full -----------------------*/
header {
  background: linear-gradient(45deg,#303f9f,#7b1fa2) !important;
  color: #ffffff;
  /*background: #673AB7;*/
  width: 100%;
  float: left;
  box-shadow: 0px 0px 15px #777;
  /*para que este estatico arriba*/
  top: 0px;
  position: absolute;
}
.wrapper {
  max-width: 1200px;
  padding: 0px 20px;
  margin: auto;
}
.logo {
  float: left;
  margin: 38px 0px;
  max-width: 200px;
}
.logo img{
  max-width: 100%;
}
/*-------------------- Navbar CSS -----------------------*/
.nav-menus {
  float: right;
  /*min-height: 100px;*/
  display: flex;
  align-items: center;
}
.nav-menus a:hover, a:focus {
  color: white;
  text-decoration: none;
  background-color: #6843ab;
  border-radius: 5px;
}
.nav-menus li {
  list-style: none;
  position: relative;
  padding: 20px 0px;
}
.nav-menus a {
  color: #fff;
  position: relative;
  font-size: 17px;
  text-decoration: none;
  padding: 15px 12px;
  z-index: 1;
}
.nav-menus li:hover {
  color: #fff;
}
.nav-menus a:after {
  content: "";
  /*width: 100%;
  height: 0px;
  background-color: #5a30a5;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 4px;
  transition: 0.5s;
  z-index: -1;*/
}
.nav-menus li:hover > a:after{
  height: 100%;
}
.nav-menus i{
  margin-right: 8px;
  font-size: 16px;
}
.toggle-btn{
  float: right;
  height: 90px;
  line-height: 90px;
  color: #fff;
  font-size: 26px;
  display: none;
  cursor: pointer;
}
.nav-menus.active{
  display: block;
}

/*-- Sub Menus CSS--*/
.sub-menus ul {
  position: absolute;
  top: 65px;
  background-color: #fff;
  padding: 5px 0px;
  min-width: 150px;
  box-shadow: 0px 0px 8px #636363;
  border-radius: 4px;
  z-index: 800;
  display: none;
}
.sub-menus:hover ul {
  display: block;
}
.sub-menus ul li {
  padding: 5px 0px;
}
.sub-menus ul li a {
  padding: 8px 10px;
  display: block;
  color: #5a30a5;
}
.sub-menus ul li:hover a {
  color: #fff;
}
.sub-menus ul::before {
  content: "";
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  position: absolute;
  top: -9px;
}

/*-- Responsive Menus Bar CSS--*/
@media (max-width:767px) {
  .toggle-btn{
    display: block;
  }
  .nav-menus {
    position: absolute;
    width: 100%;
    background: #4e2890;
    top: 100px;
    right: 0;
    display: none;
    padding: 0px 15px;
    box-sizing: border-box;
  }
  .nav-menus::before{
    content: "";
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #4e2890;
    position: absolute;
    top: -10px;
    right: 10px;
  }
  .sub-menus ul {
    position: static;
  }
  .sub-menus ul::before {
    display: none;
  }
  .nav-menus li {
    padding: 0px;
  }
  .nav-menus li a {
    display: block;
    margin: 15px 0px;
  }
}
