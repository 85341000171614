.auth-layout-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-size: cover;
  background: url(../assets/img/fondo01.jpg);
}
.auth-layout-wrap .auth-content {
  min-width: 340px;
  margin: auto;
}
.auth-logo img {
  width: 100px;
  height: 100px;
}
.form-control.form-control-rounded, .form-control.rounded {
  border-radius: 20px;
}
.btn.btn-rounded, .btn.rounded {
  border-radius: 40px !important;
}
.btn-block {
  display: block;
  width: 100%;
}

.signup-form {
  width: 450px;
  margin: 0 auto;
  padding: 30px 0;
  font-size: 15px;
}
.signup-form form {
  color: #999;
  border-radius: 3px;
  margin-bottom: 15px;
  background: #f2f3f7;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  padding: 30px;
}
.signup-form h2 {
  color: #636363;
  margin: 0 0 15px;
  position: relative;
  text-align: center;
}
.signup-form h2::before, 
.signup-form h2::after {
  content: "";
  height: 2px;
  width: 30%;
  background: #d4d4d4;
  position: absolute;
  top: 50%;
  z-index: 2;
}
.signup-form h2::before {
  left: 0;
}
.signup-form h2::after {
  right: 0;
}
.hint-text {
  color: #999;
  margin-bottom: 30px;
  text-align: center;
}
.signup-form .form-group {
  margin-bottom: 20px;
}
/* para el dashboard ----------------------------------*/
.container_main{
  padding: 30px 30px 0;
  flex: 1;
}
.card-btm-border {
  border-bottom: transparent solid 4px;
    border-bottom-color: transparent;
}
.card-shadow-primary {
  box-shadow: 0 .46875rem 2.1875rem rgba(63,106,216,.03),0 .9375rem 1.40625rem rgba(63,106,216,.03),0 .25rem .53125rem rgba(63,106,216,.05),0 .125rem .1875rem rgba(63,106,216,.03);
}
.widget-chart {
  text-align: center;
  padding: 1rem;
  position: relative;
}
.widget-chart.text-left {
  flex-direction: row;
  align-items: center;
}
.card {
  box-shadow: 0 .46875rem 2.1875rem rgba(4,9,20,.03),0 .9375rem 1.40625rem rgba(4,9,20,.03),0 .25rem .53125rem rgba(4,9,20,.05),0 .125rem .1875rem rgba(4,9,20,.03);
  border-width: 0;
    border-bottom-width: 0px;
  transition: all .2s;
}
.widget-chart2 .widget-chat-wrapper-outer {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 100%;
}
.widget-chart.text-left .widget-chart-content {
  display: flex;
  flex-direction: column;
  align-content: center;
  flex: 1;
  position: relative;
}
.opacity-5 {
  opacity: .5 !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.widget-chart.text-left .widget-numbers {
  margin-left: 0;
}
.widget-chart .widget-numbers {
  font-weight: 700;
  font-size: 2.5rem;
  display: block;
  line-height: 1;
  margin: 1rem auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: auto;
}
.widget-chart .widget-chart-flex:last-child {
  margin-bottom: 0;
}
.widget-chart2 .widget-chart-flex {
  display: flex;
  align-items: baseline;
  align-content: center;
  margin-bottom: 0;
}
.widget-chart .widget-numbers {
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1;
}
.text-success {
  color: #3ac47d !important;
}
small, .small {
  font-size: 80%;
  font-weight: 400;
}
.text-left {
  text-align: left !important;
}
.card-btm-border {
  border-bottom: transparent solid 4px;
    border-bottom-color: transparent;
}
.border-primary {
  border-color: #3f6ad8 !important;
}
.border-success {
  border-color: #3ac47d !important;
}
.border-danger {
  border-color: #d92550 !important;
}
.border-warning {
  border-color: #f7b924 !important;
}
.card-header, .card-title {
  text-transform: uppercase;
  color: rgba(13,27,62,.7);
  font-weight: 700;
  font-size: .88rem;
}
.widget-content .widget-numbers {
  font-weight: 700;
  font-size: 1.8rem;
  display: block;
}
.fsize-3 {
  font-size: 1.6rem !important;
}
.widget-content .widget-content-wrapper {
  display: flex;
  flex: 1;
  position: relative;
  align-items: center;
}
.pr-2, .px-2 {
  padding-right: .5rem !important;
}
.widget-content .widget-content-right {
  margin-left: auto;
}
/*************************************************************/
/*complemento al layout lila*/
/* hasta 768px*/
@media (max-width: 768px) {
  .navbar-header .navbar-toggle {
      display: block;
      float: right;
  }
}
/*de 768px o mayor*/
@media (min-width: 768px) {
  .navbar-toggle {
      display: none;
  }
}
.navbar-toggle {
  position: relative;
  float: right;
  padding: 9px 10px;
  margin-top: 8px;
  margin-right: 15px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.sr-only {
  white-space: nowrap;
  clip-path: inset(50%);
  background-color: white;
}
.navbar .navbar-toggle .icon-bar {
  background-color: inherit;
  border: 1px solid;
  color: #FFFFFF;
  
}
.navbar-toggle .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
  background-color: white;
  margin-top:3px;
}
/*@media (max-width: 768px) {*/
  .minav .navbar-collapse .navbar-nav > li > a {
    color: #3C4858;
    /*margin: 5px 15px;*/
    /*padding: 8px 10px;*/
    display: block;
    /*color: #5a30a5;*/
    font-size: 17px;
    text-decoration: none;
  }
/*}*/
@media (max-width: 1199px){
  .minav .navbar-nav > li > a {
      padding-top: 15px;
      padding-bottom: 15px;
  }
}
/*@media (max-width: 768px) {
  .minav .navbar-collapse .nav > li {
       padding: 0; 
       float: none; 
  }
}*/
.nav>li>a {
  position: relative;
  display: block;
  padding: 10px 15px;
}
.minav .navbar-nav > li > a {
  color: inherit;
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 400;
  font-size: 13px;
  text-transform: uppercase;
  border-radius: 3px;
}
.minav .navbar-nav > li {
  width: 100%;
}
@media (max-width: 768px) {
  .minav .navbar-collapse .navbar-nav > li > a:hover, 
  .navbar .navbar-collapse .navbar-nav > li > a:focus {
      color: #3C4858;
      
  }
}

/*-- menu popup lila*/
.menup-heading {
  font-size: .968rem;
  color: #495057;
  margin: 0;
  background: #f8f9fa;
  padding: .75rem 1.5rem;
  border-bottom: #dee2e6 solid 1px;
  border-top: #dee2e6 solid 1px;
}
.btn_close {    
    color: #888;
    float: right;
    font-size: 28px;
    font-weight: bold;
    margin: 0;
    padding: 0;
    border: none;
    outline: none !important;

}